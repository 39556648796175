<template>
  <section class="instagram-slider">
    <div v-if="payload.titelausblenden.length === 0" ref="constrain" class="constrain">
      <h3 v-text="payload.title" />
    </div>
    <Swiper
      v-if="payload.bilder.length"
      :breakpoints="swiperBreakpoints"
      :slides-per-view="1.5"
      :space-between="20"
      :slides-offset-before="slidesOffset"
      :slides-offset-after="slidesOffset"
    >
      <SwiperSlide
        v-for="bild in payload.bilder"
        :key="bild.id"
      >
        <div class="aspect a43 cover mb-4">
          <Image :src="bild" />
        </div>
        <div v-if="bild.caption" class="text-sm whitespace-pre-line" v-text="bild.caption" />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import { useWindowSize } from '@/composables/observer';
import Image from '@/components/utils/Image.vue';

export default {
  components: { Image, Swiper, SwiperSlide },
  props: { payload: { type: Object, default: Object } },
  setup() {
    const slidesOffset = ref(0);
    const swiperBreakpoints = ref({
      640: {
        slidesPerView: 2.5,
      },
      920: {
        slidesPerView: 3.5,
      },
    });

    const calculateOffset = () => {
      const constrainProps = window.getComputedStyle(document.querySelector('.constrain'));
      const marginLeft = parseFloat(
        constrainProps.getPropertyValue('margin-left').replace('px', ''),
      );
      const paddingLeft = parseFloat(
        constrainProps.getPropertyValue('padding-left').replace('px', ''),
      );
      slidesOffset.value = marginLeft + paddingLeft;
    };

    useWindowSize(calculateOffset);
    calculateOffset();

    return {
      slidesOffset,
      swiperBreakpoints,
    };
  },
};
</script>
